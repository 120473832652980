<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <BRow>

        <BCol cols="12" md="12">

          <BCard header-tag="header" footer-tag="footer">

            <div slot="header">
              <BIconShieldFillCheck/> <strong> 사용자 접근 기록 </strong>


              <div class="card-header-actions">
                <small class="text-muted">어플리케이션 접근 이력 정보를 조회합니다.</small>
              </div>
            </div>

            <BRow class="mb-2">
              <BCol>
                <BInputGroup prepend="검색"  size="sm">
                  <BFormSelect v-model="searchField"
                               size="sm"
                               style="background-color:#2f303a"
                               :options="searchOpts"/>
                  <BInputGroupAppend>
                    <BFormInput v-model="searchWord"
                                size="sm"
                                debounce="6000"
                                v-on:keyup.enter="getAccessLogs"/>
                  </BInputGroupAppend>
                </BInputGroup>
              </BCol>

              <BCol>
                <BInputGroupAppend>
                  <BFormInput v-model="fromDate" type="date" size = "sm" @input="setFromDate" @keyup.enter="getAccessLogs"/>
                  <BFormInput v-model="toDate" type="date" size="sm" @input="setToDate" @keyup.enter="getAccessLogs"/>
                </BInputGroupAppend>
              </BCol>
              <BCol class="text-right">
                <BButton size="sm" variant="info" v-b-modal.download-modal  class="ml-1">
                  <BIconFileSpreadsheetFill/> 다운로드
                </BButton>
                <BButton size="sm" variant="warning" v-b-modal.analysis-modal class="ml-1" @click="getAnalysis">
                  <BIconFileTextFill/> 상세분석
                </BButton>
                <BButton size="sm" variant="primary" v-b-modal.log-settings-modal class="ml-1">
                  <BIconGear/> 로그 저장 설정
                </BButton>
              </BCol>
            </BRow>

            <!-- User Table -->
            <BTable small sticky-header="500px" selectable hover responsive bordered dark
                    class="text-nowrap small"
                    thead-class="text-center small"
                    ref="accessTable"
                    id="accessTable"
                    select-mode="single"
                    selectedVariant="success"
                    :per-page="logPerPage"
                    :current-page="logCurrPage"
                    :items="logItems"
                    :fields="logFields"
                    @row-selected="rowSelected">
            </BTable>

            <BRow>
              <BCol>
                <BPagination
                  size="sm"
                  v-model="logCurrPage"
                  :total-rows="logRows"
                  :per-page="logPerPage"
                  aria-controls="userTable"
                ></BPagination>
              </BCol>
            </BRow>
            <BRow v-if="log">
              <BCol>
                <BBadge>Body Info</BBadge>
                <pre class="text-warning border-dark font-sm">
                  {{JSON.stringify(JSON.parse(log.body), null, 2)}}
                </pre>
              </BCol>
            </BRow>


          </BCard>

        </BCol>
      </BRow>

      <BModal ref="download-modal" id="download-modal" hide-footer
              header-bg-variant="warning"
              title="다운로드 사유 입력">
        <BFormInput class="mb-1" v-model="downloadReason" placeholder="5글자 이상 작성해주세요"/>
        <json-excel
          v-show="downloadReason.length > 4"
          class="btn btn-block btn-danger"
          :data="logItems"
          :fields="excelFields"
          worksheet="AccessLog"
          :before-generate="exportExcel"
          :before-finish= "finishDownload"
          type="xls"
          :name="fileName">
          <BIconDownload/> 다운로드
        </json-excel>



      </BModal>

      <BModal ref="analysis-modal" id="analysis-modal" hide-footer
              header-bg-variant="info"
              title="로그 상세분석">
        <div v-if="analysis">
          <BButton block variant="dark" class="mb-2">접근 이력 분석</BButton>
          <p>기간: {{ fromDate }} ~ {{ toDate }}</p>
          <p>총 요청 수: {{ analysis.totalRequests }}</p>
          <p>성공 요청 수: {{ analysis.successRate.granted }}</p>
          <p>실패 요청 수: {{ analysis.successRate.denied }}</p>

          <BButton block variant="dark" class="mb-2">요청 방법별 통계</BButton>
          <ul>
            <li v-for="(count, method) in analysis.requestsByMethod" :key="method">
              {{ method }}: {{ count }}
            </li>
          </ul>

          <BButton block variant="dark" class="mb-2">사용자별 요청 수</BButton>
          <ul>
            <li v-for="(count, user) in analysis.requestsByUser" :key="user">
              {{ user }}: {{ count }}
            </li>
          </ul>

          <BButton block variant="dark" class="mb-2">IP별 요청 수</BButton>
          <ul>
            <li v-for="(count, ip) in analysis.requestsByIP" :key="ip">
              {{ ip }}: {{ count }}
            </li>
          </ul>
        </div>
      </BModal>

      <BModal ref="log-settings-modal" id="log-settings-modal" hide-footer
        header-bg-variant="primary"
        title="로그 저장 설정">
        <BForm>
          <BRow>
            <BCol>
              <BFormGroup label="로그 보관 기간 (일)" label-for="retention-period">
                <BInputGroup>
                  <BFormInput id="retention-period" v-model="logRetentionPeriod" type="number" min="1"/>
                  <div class="input-group-append" style="margin-left: 20px;">
                    <BButton variant="primary" @click="saveLogSettings">저장</BButton>
                  </div>
                </BInputGroup>
              </BFormGroup>
            </BCol>
          </BRow>
          <BRow class="custom-margin justify-content-center">

            <BButton class="w-100" variant="secondary" @click="manualBackup">수동 백업</BButton>

          </BRow>
        </BForm>
      </BModal>

    </div>
  </div>

</template>



<script>
//-------------------------------------------------------------------------------------------------
// import moment from 'moment';
import {
  accessLogging,
  apiCall, toastSync,
} from '@/common/utils';
import qs from 'querystring';
import moment from "moment";
import JsonExcel from "vue-json-excel";

const roleMap =  {
  SA: '관리자',
  SM: '담당자',
  SS: '스텝',
};

//----------------------------------------------------------------------------------------------------
export default {
  name: 'accessLog',
  components: {
    JsonExcel
  },
  data () {
    return {
      log: null,
      isLogSelected: false,
      isLogFormShow: false,
      analysis: null,
      logFields: [
        { key: 'createdAt'  , sortable: true, label: '일시', formatter: v=>{return (v? moment(v).format('YYYY-MM-DD HH:mm:ss'):'')} },
        { key: 'userId'     , sortable: true, label: '아이디'},
        { key: 'role'       , sortable: true, label: 'Role', formatter: v=>{ return roleMap[v]}},
        { key: 'system'     , sortable: true, label: '접속시스템'},
        { key: 'ip'         , sortable: true, label: 'IP 주소' },
        { key: 'method'     , sortable: true, label: 'Method' },
        { key: 'url'        , sortable: true, label: '접속 URL' },
        // { key: 'reason'     , sortable: true, label: '사유' },
        // { key: 'result'     , sortable: true, label: '결과' },
        { key: 'params'     , sortable: true, label: 'PARAMETERS' },
        { key: 'query'      , sortable: true, label: 'QUERY_STRING' },
        // { key: 'body'       , sortable: true, label: 'BODY_STRING' },
        { key: 'device'     , sortable: true, label: 'DEVICE' },
        // { key: 'reason'     , sortable: true, label: '사유' },
        // { key: 'result'     , sortable, true, label: '결과' },
      ],
      logItems: [],
      logPerPage: 20,
      logCurrPage: 1,
      fromDate: moment(this.fromDate).add(-7,"days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      searchOpts: [
        {value:'userId', text:'사용자 아이디'},
        {value:'ip', text:'접속 IP'},
        {value:'url', text:'접속 URL'},
        {value:'method', text:'method'},
      ],
      searchWord: '',
      searchField: 'userId',
      isLastLimit: 'N',
      downloadReason:'',
      fileName: '',
      excelFields: {
        '접근일시': {field: 'createdAt', callback: (v)=>{ return moment(v).format('YYYY-MM-DD HH:mm:ss')}},
        '아이디': 'userId'    ,
        '역할': {field:'role', callback: (v)=>{ return roleMap[v]}},
        '시스템': 'system',
        'IP': 'ip',
        '접근방법': 'method',
        'URL': 'url',
        '사유': 'reason',
        '결과': 'result',
        '파라미터': 'params',
        'QUERY': 'query',
        'BODY': 'body',
        'DEVICE': 'device',
      },
      logRetentionPeriod: 730,
    }
  },

  async created(){
    try{
      await this.getAccessLogs();
    }catch(err){
      console.log(err);
    }
  },

  computed: {
    logRows(){
      return this.logItems.length;
    }
  },

  methods: {
    setFromDate(){
      this.toDate =  moment(this.fromDate).add(7,"days").subtract(1,'day').format("YYYY-MM-DD");
    },

    setToDate(){
      if( this.toDate < this.fromDate) this.fromDate = this.toDate
      //this.fromDate =  moment(this.toDate).startOf("month").format("YYYY-MM-DD");
    },

    async getAccessLogs(){
      let param = {};

      try{

        if( this.searchWord ){
          param[this.searchField] = this.searchWord;
        }

        param['toDate'] = this.toDate;
        param['fromDate'] = this.fromDate;

        const {result} = await apiCall('get', `/api/access/log/query?${qs.stringify(param)}`);
        this.logItems = result

      }catch(err){
        console.log( err );
      }
    },

    async getAnalysis() {
      let param = {};

      try {
        param['toDate'] = this.toDate;
        param['fromDate'] = this.fromDate;

        const { result } = await apiCall('get', `/api/access/log/analysis?${qs.stringify(param)}`);
        this.analysis = result.analysis;
      } catch (err) {
        console.log(err);
      }
    },


    rowSelected(item){
      console.log("------------ rowSelected ------------");
      if( item.length === 0 ){
        this.log = null;
        return;
      }else{
        // console.log( "********** user----------------->", JSON.stringify(item[0]) );
        this.log = item[0];
        return;
      }
    },

    finishDownload(){
      this.$refs['download-modal'].hide();
    },

    async exportExcel(){
      try{
        this.fileName = `access-log_${this.fromDate}_${this.toDate}.xls`;

        console.log( '------before-generate startDownload-----------', this.fileName);
        if( !this.downloadReason || this.downloadReason.length < 5 ){
          return await toastSync(this.$bvToast, "[사유 없음] 다운로드 사유는 5자 이상 입력해야 합니다.", 'danger');
        }
        const {currentRoute} = this.$router;
        const rs = await accessLogging(
          'download',
          currentRoute,
          '',
          this.downloadReason,
          `레코드수: ${this.logItems.length}, 파일명: ${this.fileName}`
        );
        if(rs===true){
          this.downloadReason = '';
          return true;
        }else{
          await toastSync(this.$bvToast, "Error: "+rs.message, 'danger');
          return false;
        }

      }catch(err){
        console.log(err);
        return false;
      }
    },
    saveLogSettings() {
      console.log('Saving log settings:', this.logRetentionPeriod);
    },
    manualBackup() {
      console.log('Manual backup initiated');
    },

  }
}
</script>
<style scoped>
.custom-margin {
  margin: 15px;
}
</style>

